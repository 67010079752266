import React, { useState } from 'react';
import axios from 'axios';
import "./EmpForgot.css";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Forgot = () => {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [userId, setUserId] = useState('');
  const [error, setError] = useState('');
  const [isSendingOtp, setIsSendingOtp] = useState(false);

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };

  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleSendOtp = async () => {
    setIsSendingOtp(true);
    try {
      const response = await axios.post('https://api.payroll.yatayati.com/request-otp', { userId });
      toast.success("OTP sent successfully");
      console.log(response);
    } catch (error) {
      toast.error('There was an error sending the OTP!');
      console.error('There was an error!', error);
    } finally {
      setIsSendingOtp(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');

    try {
      const response = await axios.post('https://api.payroll.yatayati.com/validate-otp', { userId, otp: otpValue });
      if (response.data.valid) {
        navigate('/Reset');
      } else {
        setError('Wrong OTP entered. Please try again.');
      }
    } catch (error) {
      console.error('There was an error!', error);
      setError('An error occurred while validating OTP.');
    }
  };

  return (
    <div className="forgot-container">
      <h2>Forgot Password</h2>
      <p className="description">
        Please enter your UserID and the OTP sent to your email address to reset your password.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>UserID:</label>
          <input 
            type="text" 
            value={userId} 
            onChange={handleUserIdChange} 
            required 
          />
        </div>
        <div className="form-group">
          <label>OTP:</label>
          <div className="otp-container">
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-${index}`}
                type="text"
                value={digit}
                onChange={(e) => handleOtpChange(e, index)}
                maxLength="1"
                required
              />
            ))}
          </div>
        </div>
        {error && <p className="error">{error}</p>}
        <button 
          type="button" 
          onClick={handleSendOtp} 
          disabled={isSendingOtp}
        >
          {isSendingOtp ? 'Sending OTP...' : 'Send OTP'}
        </button>
        <button type="submit" style={{ marginLeft: "40px" }}>
          Next
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Forgot;
