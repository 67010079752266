import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Menu,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import EmpSidebar from "./EmpSidebar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const Datagrid = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState([]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "EmployeeDashboard") {
        navigate("/");
      }
    }
  }, []);

  const fetchnameData = async () => {
    try {
      const response = await axios.get(
        `https://api.payroll.yatayati.com/loginattend/${username}`
      );
      // const loggedInUser = response.data.user;
      const sortedData = response.data.user.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setUser(sortedData);
      resetClockInOutState();
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchnameData();
    }
  }, [username]);

  const resetClockInOutState = () => {
    const storedClockInTime = localStorage.getItem(`clockInTime_${username}`);

    if (storedClockInTime) {
      setClockInTime(storedClockInTime);
      setTimerRunning(true);
    } else {
      setClockInTime(null);
      setClockOutTime(null);
      setTimerRunning(false);
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sendRequestPopupOpen, setSendRequestPopupOpen] = useState(false);
  const [newRequest, setNewRequest] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const [clockInOutAction, setClockInOutAction] = useState("");
  const [duplicateDateDialogOpen, setDuplicateDateDialogOpen] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [attendance, setAttendance] = useState("");
  const [data, setData] = useState([]);
  const [sendCommentPopupOpen, setSendCommentPopupOpen] = useState(false);
  const [wrongDatePopupOpen, setWrongDatePopupOpen] = useState(false);
  const [clockInOutPopupOpen, setClockInOutPopupOpen] = useState(false);
  const [clockInOutMessage, setClockInOutMessage] = useState("");

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(user.length / rowsPerPage)));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSendCommentPopupOpen = () => {
    setSendCommentPopupOpen(true);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  let filteredData = [...user];

  if (selectedDate) {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    filteredData = filteredData.filter(
      (item) => moment(item.Date).format("YYYY-MM-DD") === formattedDate
    );
  }

  const displayedRows = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
  .sort((a, b) => new Date(a.Date) - new Date(b.Date));;
  const totalPages = Math.ceil(user.length / rowsPerPage);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {

    axios
      .get("https://api.payroll.yatayati.com/attendance")
      .then((response) => {
        setData(response.data);
      })

      .catch((error) => {
        console.error("Error fetching attendance:", error);
      });
  };

  const handleCloseDialog = () => {
    setDuplicateDateDialogOpen(false);
  };

  const handleSendComment = () => {
    if(!newRequest) {
      toast.warning("Please write a Comment");
      return;
    }
    const formattedDate = moment(selectedRow.Date).format(
      "YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const requestData = {
      EmployeeID: username,
      date: formattedDate,
      comment: newRequest,
    };
    axios
      .post("https://api.payroll.yatayati.com/save-comment", requestData)

      .then(() => {
        console.log("Comment saved successfully");
        toast.success("Comment sent successfully");
        handleClose();
      })
      .catch((error) => {
        console.error("Error saving comment:", error);

        toast.error("Error sending comment");
      });

    setSendCommentPopupOpen(false);
  };

  const handleSendCommentPopupClose = () => {
    setSendCommentPopupOpen(false);
  };

  useEffect(() => {
    const storedClockInTime = localStorage.getItem("clockInTime");
    if (storedClockInTime) {
      setClockInTime(storedClockInTime);
      setTimerRunning(true);
    } else {
      setClockInTime(null);
    }
    const intervalId = setInterval(() => {}, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleClockInOut = () => {

    if (timerRunning) {
      const clockOut = moment().format("HH:mm:ss");
      setClockOutTime(clockOut);
      const duration = calculateDuration(clockInTime, clockOut);
      saveClockOutData(clockOut, duration);
      setTimerRunning(false);
      localStorage.removeItem(`clockInTime_${username}`);
      setClockInOutMessage(`Clocked out at ${clockOut}`);
      setClockInOutPopupOpen(true);
    } else {
      const clockIn = moment().format("HH:mm:ss");
      setClockInTime(clockIn);
      setTimerRunning(true);
      localStorage.setItem(`clockInTime_${username}`, clockIn);
      setClockInOutMessage(`Clocked in at ${clockIn}`);
      setClockInOutPopupOpen(true);
    }
  };

  const calculateDuration = (clockInTime, clockOutTime) => {

    const start = moment(clockInTime, "HH:mm:ss");
    const end = moment(clockOutTime, "HH:mm:ss");
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };

  const saveClockOutData = (clockOutTime) => {

    const clockIn = moment(clockInTime, "HH:mm:ss");
    const clockOut = moment(clockOutTime, "HH:mm:ss");
    const duration = moment.duration(clockOut.diff(clockIn));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let status = "Absent";

    if (hours >= 8) {
      status = "Present";
    } else if (hours >= 4) {
      status = "Half-day";
    }

    const requestData = {
      employeeId: username,
      date: moment().format("YYYY-MM-DD"),
      clockIn: clockInTime,
      clockOut: clockOutTime,
      duration: `${hours}:${minutes}:${seconds}`,
      status: status,
      comment: "",
      officeId: username,
    };

    axios
      .post("https://api.payroll.yatayati.com/clock-out", requestData)
      .then(() => {
        fetchData();
        setClockOutTime(clockOutTime);
        fetchnameData();
        toast.success("Attendance marked successful");
      })

      .catch((error) => {
        console.error("Error saving clock out data:", error);
        toast.error("You have already mark Attendance for this date ");
      });
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <>
      <EmpSidebar />
      <>
        <div>
          <div className="emp-electro">
            <h3 className="header1">Add Attendance</h3>
          </div>

          <div className="datagrid" style={{ marginTop: "30px", padding:"15px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <Button
                    variant="contained"
                    color={timerRunning ? "secondary" : "primary"}
                    onClick={() =>
                      handleClockInOut(timerRunning ? "clockOut" : "clockIn")
                    }
                    style={{
                      marginLeft: "10px",
                      marginBottom: "20px",
                    }}
                  >
                    {timerRunning ? "Clock Out" : "Clock In"}
                  </Button>
                </div>

                <div style={{ display: "block", marginLeft: "20px" }}>
                  {clockInTime && <p>Clock In Time: {clockInTime}</p>}
                  {clockOutTime && <p>Clock Out Time: {clockOutTime}</p>}
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", marginTop:"-30px" }}>
                  <TextField
                  type="date"
                  // label="Select Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  />
              </div>
            </div>

            <TableContainer component={Paper} >
              <Table aria-label="simple table">
                <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
                  <TableRow className="emp-css-1ygcj2i-MuiTableCell-root">
                    <TableCell className="super-stickyCell">Date</TableCell>
                    <TableCell>Clock in</TableCell>
                    <TableCell>Clock out</TableCell>
                    <TableCell>Working Time</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {displayedRows.map((userData) => (
                    <TableRow key={userData.EmployeeID}>
                      <TableCell className="super-stickyCell"> {moment(userData.Date).format("YYYY-MM-DD")}</TableCell>
                      <TableCell>{userData.clockIN}</TableCell>
                      <TableCell>{userData.clockout}</TableCell>
                      <TableCell>{userData.TotalTime}</TableCell>
                      <TableCell>{userData.Status}</TableCell>
                      <TableCell>
                        <Button onClick={(event) => handleClick(event, userData)}>
                          <MoreHorizIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div
              style={{display: "flex", justifyContent: "flex-end", marginTop: "1rem",}}>
              <div>
                <Button disabled={currentPage === 1} onClick={handlePrevPage}>
                  <FaChevronLeft />
                </Button>
                <span style={{ margin: "0 10px" }}>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}>
                  <FaChevronRight />
                </Button>
              </div>

              <Typography variant="body2">
                <select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  style={{ width: "40px", height: "28px" }}>
                  <option value={4}>4</option>
                  <option value={8}>8</option>
                  <option value={12}>12</option>
                </select>
              </Typography>
            </div>

            {selectedRow && (
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={handleSendCommentPopupOpen}>Send</MenuItem>
              </Menu>
            )}

            <Dialog
              open={confirmationPopupOpen}
              onClose={() => setConfirmationPopupOpen(false)}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>
                Confirm{" "}
                {clockInOutAction === "clockIn" ? "Clock In" : "Clock Out"}
              </DialogTitle>

              <DialogContent>
                <Typography variant="body1">
                  Are you sure you want to{" "}
                  {clockInOutAction === "clockIn" ? "clock in" : "clock out"}?
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => setConfirmationPopupOpen(false)}
                  color="primary">  No</Button>
                <Button
                  onClick={() => {
                    if (clockInOutAction === "clockIn") {
                      const clockIn = moment().format("HH:mm:ss");
                      setClockInTime(clockIn);
                      setTimerRunning(true);
                      localStorage.setItem(`clockInTime_${username}`, clockIn);
                      setClockInOutMessage(`Clocked in at ${clockIn}`);
                    } else {
                      const clockOut = moment().format("HH:mm:ss");
                      setClockOutTime(clockOut);
                      setTimerRunning(false);
                      localStorage.removeItem(`clockInTime_${username}`);
                      setClockInOutMessage(`Clocked out at ${clockOut}`);
                    }
                    setConfirmationPopupOpen(false);
                    setClockInOutPopupOpen(true);
                  }}
                  color="primary">Yes
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={sendCommentPopupOpen}
              onClose={handleSendCommentPopupClose}
            >
              <DialogTitle>Send Comment</DialogTitle>

              <DialogContent>
                <TextField
                  label="Comment"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  value={newRequest}
                  onChange={(e) => setNewRequest(e.target.value)}
                  className="emp-textField"
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={handleSendCommentPopupClose} color="primary">
                  Cancel
                </Button>

                <Button onClick={handleSendComment} color="primary">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={wrongDatePopupOpen}
              onClose={() => setWrongDatePopupOpen(false)}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Error</DialogTitle>

              <DialogContent>
                <Typography variant="body1">
                  Attendance can only be added for the current date.
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => setWrongDatePopupOpen(false)}
                  color="primary"
                >
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={clockInOutPopupOpen}
              onClose={() => setClockInOutPopupOpen(false)}
              maxWidth="sm"
              fullWidth>
              <DialogTitle>Clock In/Out</DialogTitle>
              <DialogContent>
                <Typography variant="body1">{clockInOutMessage}</Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={() => setClockInOutPopupOpen(false)}
                  color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog open={duplicateDateDialogOpen} onClose={handleCloseDialog}>
              <DialogTitle id="duplicate">Duplicate Entry</DialogTitle>

              <DialogContent>
                <DialogContentText id="duplicate-date">
                  Your attendance for this date is already marked.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>

            <ToastContainer />
          </div>
        </div>
      </>
    </>
  );
};

export default Datagrid;
