import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import Cookies from 'js-cookie';
import "./SuperHome.css";

function Sidebar() {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleAddClick = () => {
    navigate('/SuperAdd'); 
  };

  const handleLogout = () => {
    setShowConfirmation(true);
  };

  const confirmLogout = () => {
    Cookies.remove('token'); 
    navigate('/');
    setShowConfirmation(false);
  };

  return (
    <header className="header">
      <div className="menu-icon">
      </div>

      <div className="header-right1">
        
        <button type="button" className="add-icon" onClick={handleAddClick}>
          Add+
        </button>
        <div className="cursor">
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>

      {showConfirmation && (
  <div className="modal-backdrop" style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  }}
  >
    <div
      className="modal-content" style={{
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '5px',
        width: '90%',
        maxWidth: '400px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <h4 className="modal-title">Confirm Logout</h4>
      <p>Are you sure you want to logout?</p>      
      <div className="modal-footer" style={{ textAlign: 'right' }}>
        <button onClick={confirmLogout} className="btn btn-primary" style={{ marginRight: '10px' }}>
          OK
        </button>
        <button
          onClick={() => setShowConfirmation(false)} className="btn btn-secondary">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}


    </header>
  );
}

export default Sidebar;
