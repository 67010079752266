import React, { useState, useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const Spend = () => {
  const navigate = useNavigate();

  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
  const [data, setData] = useState([]);
  const cardinal = curveCardinal.tension(0.2);

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    } else {
      try {
        const decoded = jwtDecode(token);

        setUsername(decoded.username);
        setRole(decoded.role);

        if (decoded.role !== "HrHome") {
          navigate("/");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        navigate("/");
      }
    }
  }, [navigate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.payroll.yatayati.com/api/employee-count-by-joining-month/${username}`
      );
      const jsonData = response.data;

      const filteredData = jsonData.filter((item) => item.employeeCount !== undefined && item.month >= 1);

      console.log("Fetched Data:", filteredData);

      setData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchData();
    }
  }, [username]);

  return (
    <>
      <h4 className="text-primary shadow-sm p-2 mb-4 bg-body rounded">
        New Hires
      </h4>
      <ResponsiveContainer width="100%" height={375}>
        <AreaChart
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 100 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month"
            tickFormatter={(monthIndex) => monthNames[monthIndex - 1]} // Adjust index since months are 1-indexed
          />
          <YAxis />
          <Tooltip
            labelFormatter={(value) => monthNames[value - 1]} // Adjust index since months are 1-indexed
            formatter={(value) => [`Employee Count: ${value}`]} // Customize tooltip content
          />
          <Area
            type={cardinal}
            dataKey="employeeCount"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.3}
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default Spend;
