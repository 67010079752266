import React, { useEffect, useState } from 'react';
import EmpNavbar from './EmpNavbar';
import EmpSidebar from './EmpSidebar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios'; // Import axios
import { Button, TextField, MenuItem, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const FileDownload = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  // const [month, setMonth] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'EmployeeDashboard') {
        navigate('/');
      }
    }
  }, [navigate]);

  // const handleMonthChange = (event) => {
  //   setMonth(event.target.value);
  // };


  return (
    <>
      <EmpSidebar />
      <EmpNavbar />
      <Box sx={{ padding: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '2rem',
            marginBottom: '1rem'
          }}
        >
          <TextField variant="outlined" label="EmployeeID" />
          {/* <TextField
            variant="outlined"
            select
            label="Select Month"
            value={month}
            onChange={handleMonthChange} style={{ width: "150px" }}>

            <MenuItem value="January">January</MenuItem>
            <MenuItem value="February">February</MenuItem>
            <MenuItem value="March">March</MenuItem>
            <MenuItem value="April">April</MenuItem>
            <MenuItem value="May">May</MenuItem>
            <MenuItem value="June">June</MenuItem>
            <MenuItem value="July">July</MenuItem>
            <MenuItem value="August">August</MenuItem>
            <MenuItem value="September">September</MenuItem>
            <MenuItem value="October">October</MenuItem>
            <MenuItem value="November">November</MenuItem>
            <MenuItem value="December">December</MenuItem>
          </TextField> */}
        </Box>
        <Button variant="contained" color="primary" size="small">Download</Button>
      </Box>
      <ToastContainer/>
    </>
  );
};

export default FileDownload;
