import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = [
  {
    path: '/hrEmployee',
    name: 'Adding Employee',
  },
  {
    path: '/HrJobDetails',
    name: 'All Details',
  },
];

const NavbarEmp = () => {
  return (
    <div>
      <div className='hori' style={{ display: 'flex', alignItems: 'center', padding:"15px" }}>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            activeClassName='active'>
            <div className='font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
        {/* <button
          type="submit"
          style={{
            marginLeft: 'auto', 
            backgroundColor: '#007bff', 
            color: 'white', 
            padding: '2px 16px', 
            border: 'none', 
            borderRadius: '4px', 
            cursor: 'pointer' 
          }}>
          Save
        </button> */}
      </div>
    </div>
  );
};

export default NavbarEmp;
