import React, { useEffect, useState } from "react";
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import HrSidebar from './HrSidebar';
import Week from './HrWeek';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
 
const WeekOff = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);

  const [addWeekOpen, setAddWeekOpen] = useState(false);
  const [newWeekOff, setNewWeekOff] = useState("");
  const [newDay, setNewDay] = useState("");
  const [typeDate, setTypeDate] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
 
  const fetchWeekData = (() => {
    axios
      .get(`https://api.payroll.yatayati.com/weekoff/inn/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching weekoff data:", error);
      });
  })
  useEffect(() => {
    fetchWeekData();
  }, [username]);
 

 
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
 
  const handleOpenWeekDialog = () => {
    setAddWeekOpen(true);
  };
 
  const handleCloseAddWeekDialog = () => {
    setAddWeekOpen(false);
  };
 
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handleClose = () => {
    setAnchorE1(null);
    setSelectedRow(null);
  };
 
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (editedRow && editedRow.WeekOffName) {
      axios
        .put(
          `https://api.payroll.yatayati.com/weekoff/${editedRow.WeekOffName}`,
          editedRow
        )
        .then((response) => {
          fetchWeekData();
          handleClose();
          setEditDialogOpen(false);
          setEditedRow(null);
          console.log("PUT request successful:", response.data); // Log updated data
          toast.success("Record updated successfully");
          setData((prevData) =>
            prevData.map((item) => (item.id === editedRow.id ? editedRow : item))
          );
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
    }
  };
 
 
  const handleAddWeek = () => {
    if (!newWeekOff || !selectedDays.join(", ") ) {
      toast.warning("Please fill Name & Day");
      return;
    }
    const newWeek = {
      WeekOffName: newWeekOff,
      Day: selectedDays.join(", "),
      Description: newDescription,
      OfficeID: username,
    };
    axios
      .post("https://api.payroll.yatayati.com/weekoff", newWeek)
      .then(() => {
        console.log("Week-off added successfully");
        toast.success("Week-off added successfully");
        // Reset input fields
        setNewWeekOff("");
        setSelectedDays([]);
        setNewDescription("");
        fetchWeekData();
        handleCloseAddWeekDialog();
      })
      .catch((error) => {
        console.error("Error adding week-off:", error);
      });
  };
 
  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
 
  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
    handleClose(); // Close menu after opening edit dialog
  };
 
  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
  };
 
  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };
 
  const handleClick = (event, row) => {
    setAnchorE1(event.currentTarget);
    setSelectedRow(row);
  };
 
  const handleDeleteConfirmed = () => {
    if (selectedRow && selectedRow.WeekOffName) {
      axios
        .delete(`https://api.payroll.yatayati.com/weekoff/${selectedRow.WeekOffName}`)
        .then(() => {
          setData((prevData) =>
            prevData.filter(
              (row) => row.WeekOffName !== selectedRow.WeekOffName
            )
          );
          fetchWeekData();
          console.log("Record deleted successfully");
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
        });
    } else {
      console.error("No selected row or WeekOffName found.");
    }
    handleClose();
    handleDeleteConfirmationClose();
  };
 
  let filteredData = [...data];
  if (searchQuery) {
    filteredData = filteredData.filter((row) =>
      row.WeekOffName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
      <HrSidebar />
      <Week />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="alien">
          <div className="mars">
            <TextField
              variant="outlined"
              label="WeekOff Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super-text"
              style={{ marginLeft: "10px" }}
            ></TextField>
          </div>
          <div className="moon">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenWeekDialog}>
              + Add WeekOff
            </Button>
 
            <Dialog open={addWeekOpen} onClose={handleCloseAddWeekDialog}>
              <DialogTitle>Add New WeekOff's</DialogTitle>
              <DialogContent>
                <TextField
                  label="WeekOff Name"
                  value={newWeekOff}
                  onChange={(e) => setNewWeekOff(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  select
                  label="Day"
                  value={selectedDays}
                  onChange={(e) => setSelectedDays(e.target.value)}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(", "),
                  }}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Monday">Monday</MenuItem>
                  <MenuItem value="Tuesday">Tuesday</MenuItem>
                  <MenuItem value="Wednesday">Wednesday</MenuItem>
                  <MenuItem value="Thursday">Thursday</MenuItem>
                  <MenuItem value="Friday">Friday</MenuItem>
                  <MenuItem value="Saturday">Saturday</MenuItem>
                  <MenuItem value="Sunday">Sunday</MenuItem>
                  
                </TextField>
 
                <TextField
                  label="Description"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddWeekDialog} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleAddWeek} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
 
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell>Week Off Name</TableCell>
                <TableCell>Day</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.WeekOffName}</TableCell>
                  <TableCell>{row.Day}</TableCell>
                  <TableCell>{row.Description || 'None'}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
 
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>
 
        {selectedRow && (
          <Menu
            anchorEl={anchorE1}
            open={Boolean(anchorE1)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmationOpen}>Delete</MenuItem>
          </Menu>
        )}

          <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="WeekOff Name :"
                value={editedRow ? editedRow.WeekOffName : ""}
                onChange={(e) => handleEditFormChange(e, "WeekOffName")}
                fullWidth
                margin="normal"
                disabled
              />
              <Select
                label="Day :"
                value={editedRow ? editedRow.Day : ""}
                onChange={(e) => handleEditFormChange(e, "Day")}
                fullWidth
                margin="normal"
              >
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </Select>
              <TextField
                label="Description :"
                value={editedRow ? editedRow.Description : ""}
                onChange={(e) => handleEditFormChange(e, "Description")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
 
        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleDeleteConfirmationClose}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteConfirmationClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
 
      </div>
      <ToastContainer />
    </>
  );
};
 
export default WeekOff;
 