import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsEmojiSunglasses, BsRepeat, BsJustify, BsPersonCircle } from 'react-icons/bs';
import { MdLogout, MdHome } from 'react-icons/md';
import { BsEscape } from 'react-icons/bs';
import { SiNginxproxymanager } from "react-icons/si";
import { IoIosNotifications } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import Cookies from 'js-cookie';
import axios from 'axios';
import './HrSidebar.css';


function Sidebar() {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [filteredAttendanceRequests, setFilteredAttendanceRequests] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false); // State for showing logout confirmation

  useEffect(() => {
    fetchAttendanceRequests();
    const interval = setInterval(fetchAttendanceRequests, 60000); // Fetch every minute
    return () => clearInterval(interval);
  }, []);

  const fetchAttendanceRequests = () => {
    axios
      .get("https://api.payroll.yatayati.com/attendance")
      .then((response) => {
        const filteredRequests = response.data.filter(
          (request) => request.Comment !== null && request.Comment !== "none"
        );
        setFilteredAttendanceRequests(filteredRequests);
        setNotificationCount(filteredRequests.length > 0 ? 1 : 0);
      })
      .catch((error) => {
        console.error("Error fetching attendance requests:", error);
      });
  };

  const fetchLeaveRequests = () => {
    axios
      .get("https://api.payroll.yatayati.com/api/Leave")
      .then((response) => {
        setLeaveRequests(response.data);
        setNotificationCount(filteredAttendanceRequests.length + response.data.length);
      })
      .catch((error) => {
        console.error("Error fetching leave requests:", error);
      });
  };

  useEffect(() => {
    fetchLeaveRequests();
  }, []);

  useEffect(() => {
    const count = filteredAttendanceRequests.filter(
      (request) => request.Comment && request.Comment !== "none"
    ).length;
    setNotificationCount(count);
  }, [filteredAttendanceRequests]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleItemClick = (e) => {
    if (e.target.tagName !== 'A') {
      setOpenSidebarToggle(false);
    }
  };

  const handleLogout = () => {
    setShowLogoutConfirmation(true); 
  };

  const handleConfirmLogout = () => {
    setShowLogoutConfirmation(false); 
    Cookies.remove('token');
    navigate('/');
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutConfirmation(false); 
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        (!sidebarRef.current || !sidebarRef.current.contains(event.target)) &&
        (!notificationRef.current || !notificationRef.current.contains(event.target))
      ) {
        setOpenSidebarToggle(false);
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const notificationRef = useRef(null);

  return (
    <>
      <aside
        id="sidebar"
        ref={sidebarRef}
        className={`${openSidebarToggle ? 'sidebar-responsive' : ''} ${
          !openSidebarToggle ? 'sidebar-closed' : ''
        }`} >
        <div className="sidebar-title">
          <div className="sidebar-brand">
            <SiNginxproxymanager className="icon_header" /> HR
          </div>
        </div>

        <ul className="sidebar-list">
          <Link to="/hrhome" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <MdHome className="icon" /> Dashboard
            </li>
          </Link>

          <Link to="/hremployee" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsPeopleFill className="icon" /> Employee
            </li>
          </Link>

          <Link to="/hrAttendance" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsFillGrid3X3GapFill className="icon" /> Attendance
            </li>
          </Link>

          <Link to="/hrLeaveData" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEscape className="icon" /> Leave
            </li>
          </Link>

          <Link to="/hrPayroll" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsListCheck className="icon" /> Payroll
            </li>
          </Link>

          <Link to="/hrHolidays" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEmojiSunglasses className="icon" /> Holidays
            </li>
          </Link>

          <Link to="/hrOrganization" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsRepeat className="icon" /> Organization
            </li>
          </Link>

          <Link to="/MyProfile" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <CgProfile className="icon" /> Profile
            </li>
          </Link>

          <Link to="" className="sidebar-link" onClick={handleItemClick}>
          <li className="sidebar-list-item" onClick={handleLogout}>
            <MdLogout className="icon" /> LogOut
          </li>
          </Link>
        </ul>
      </aside>

      {showLogoutConfirmation && (
  <div className="modal-backdrop">
    <div className="modal-content">
      <h4 className="modal-title">Confirm Logout</h4>
      <p>Are you sure you want to logout?</p>
      <div className="modal-footer">
        <button onClick={handleCloseLogoutModal} className="btn btn-secondary">
          Cancel
        </button>
        <button onClick={handleConfirmLogout} className="btn btn-primary">
          Logout
        </button>
      </div>
    </div>
  </div>
)}

      <header className='header'>
        <div className='menu-icon'>
          <BsJustify className='icon' onClick={OpenSidebar} />
        </div>
      </header>
    </>
  );
}

export default Sidebar;
