import React, { useEffect, useState } from 'react';
import axios from "axios";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import AdminSidebar from './AdminSidebar';
import Week from './AdminOrgNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
 
const LeavesDeclare = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, []);

  const [addNoticeOpen, setAddNoticeOpen] = useState(false);
  const [newNoticePeriod, setNewNoticePeriod] = useState("");
  const [duration, setDuration] = useState("");
  const [rowsPerPages, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [type, setType] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [data, setData] = useState([]);
  const [newid, setNewID] = useState("");
  const [PerMonth, setperMonth] = useState("");
 
  const fetchLeave = (() => {
    axios.get (`https://api.payroll.yatayati.com/api/LeavesDeclare/get/${username}`).then((response) => {
      setData(response.data);
  })
        .catch((error) => {
      console.error("Error fetching Leave data:", error);
    });
  })


useEffect(() => {
  fetchLeave();
}, [username]);
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleClick = (event, row) => {
    setAnchorE1(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setSelectedRow(null);
  };

  const handleFormSubmit = () => {
      axios.put(`https://api.payroll.yatayati.com/api/update/LeavesDeclare/${selectedRow.id}`,selectedRow)
        .then((response) => {
          handleClose();
          fetchLeave();
          toast.success('Record updated successfully');
          setData(prevData => prevData.map(row => row.id === selectedRow.id ? selectedRow : row));
        })
        .catch((error) => {
          console.error('Error updating record:', error);
          toast.error('Failed to update record');
        });
  };

  const handleCloseAddNotice = () => {
    setAddNoticeOpen(false);
  };
  const handleCloseAddNoticeDialog = () => {
    setAddNoticeOpen(false);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
 
 
  const handleDeleteConfirmed = () => {
    console.log("Selected Row:", selectedRow);
      axios.delete(`https://api.payroll.yatayati.com/api/LeavesDeclare/delete/${selectedRow.LeaveType}`)
        .then(() => {
          toast.success('Record deleted successfully');
          fetchLeave();
      })
        .catch((error) => {
          console.error('Error deleting record:', error);
        });
    handleClose(); 
    handleDeleteCancelled(); 
  };  
 
  const handleFormChange = (event) => {
    const { id, value } = event.target;
    setSelectedRow((prevSelectedRow) => ({
      ...prevSelectedRow,
      [id]: value,
    }));
  };

  const handleAddNotice = () => {
    if (!newNoticePeriod || !duration || !PerMonth) {
      toast.warning("Please fill in LeaveType & Days & PerMonth");
      return;
    }
    const newNotice = {
      id: newid,
      LeaveType: newNoticePeriod,
      Days: duration,
      PerMonth: PerMonth,
      OfficeId: username,
    };
    axios.post('https://api.payroll.yatayati.com/api/LeavesDeclare', newNotice)
      .then(() => {
        console.log('notice added successfully');
        toast.success('Leaves added successfully');
        setNewNoticePeriod("");
        setDuration("");
        setperMonth("");
        fetchLeave();
        handleCloseAddNotice();
      })
      .catch((error) => {
        console.error('Error adding notice:', error);
      });
  };
 
  const handleOpenNoticeDialog = () => {
    setAddNoticeOpen(true);
  };
 
  let filteredData = [...data];
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPages);
  const startIndex = (currentPage - 1) * rowsPerPages;
  const endIndex = Math.min(startIndex + rowsPerPages, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
    <AdminSidebar/>
      <Week />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
  <div className="alien">
    <div className='moon'>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleOpenNoticeDialog}
      >
        + Add Leave/Year
      </Button>
 
      <Dialog open={addNoticeOpen} onClose={handleCloseAddNotice}>
        <DialogTitle>Add New Leaves</DialogTitle>
        <DialogContent>
          <TextField
            label="Leave Type"
            value={newNoticePeriod}
            onChange={(e) => setNewNoticePeriod(e.target.value)}
            fullWidth
            margin="normal"
          />
 
          <TextField
            label="Per Year"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            fullWidth
            margin="normal"/>

          <TextField
            label="Per Month"
            value={PerMonth}
            onChange={(e) => setperMonth(e.target.value)}
            fullWidth
            margin="normal"
          />  

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddNoticeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddNotice} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  </div>
 
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
        <TableRow>
          <TableCell
            className="super-sticky-cell"
            style={{
              overflow: "auto",
              borderRight: "2px solid lightblue",
            }}
          >
            Leave Type
          </TableCell>
          <TableCell>Per Year</TableCell>
          <TableCell>Per Month</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {currentData.map((row) => (
          <TableRow key={row.LeaveType}>
              <TableCell> {row.LeaveType}</TableCell>
             <TableCell> {row.PerYear}</TableCell>
            <TableCell> {row.PerMonth}</TableCell>
            <TableCell>
              <Button onClick={(event) => handleClick(event, row)}>
                <MoreHorizIcon />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
 
  <div
    style={{
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "1rem",
    }}
  >
    <div>
      <Button disabled={currentPage === 1} onClick={handlePrevPage}>
        <FaChevronLeft />
      </Button>
      <span style={{ margin: "0 10px" }}>
        Page {currentPage} of {totalPages}
      </span>
      <Button
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
      >
        <FaChevronRight />
      </Button>
    </div>
    <Typography variant="body2">
      {" "}
      <select
        value={rowsPerPages}
        onChange={handleRowsPerPageChange}
        style={{ width: "40px", height: "28px" }}
      >
        <option value={4}>4</option>
        <option value={8}>8</option>
        <option value={12}>12</option>
      </select>
    </Typography>
  </div>
 
  {selectedRow && (
    <Menu
      anchorEl={anchorE1}
      open={Boolean(anchorE1)}
      onClose={handleClose}
    >
      {/* <MenuItem>
        <span
          style={{ textDecoration: "none" }}
          data-bs-toggle="offcanvas"
          data-bs-target="#editOffcanvas"
          aria-controls="editOffcanvas"
        >
          Edit
        </span>
        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="editOffcanvas"
          aria-labelledby="editOffcanvasLabel"
          style={{ width: "400px", overflowY: "auto" }}
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="editOffcanvasLabel">
              Edit Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form onSubmit={handleFormSubmit}>
              <div className="mb-3">
                <label htmlFor="noticePeriod" className="form-label">
                  Leave Type:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="LeaveType"
                  value={selectedRow.LeaveType}
                  onChange={handleFormChange}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="noticePeriodduration"
                  className="form-label"
                >
                  How many Days:
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="days"
                  value={selectedRow.days}
                  onChange={handleFormChange}
                />
              </div>
              <div className="d-flex">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-secondary mr-2"
                  data-bs-dismiss="offcanvas"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MenuItem> */}
      <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
    </Menu>
  )}
  <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      Are you sure you want to delete this record?
    </DialogContent>
    <DialogActions>
      <Button onClick={handleDeleteCancelled} color="primary">
        Cancel
      </Button>
      <Button onClick={handleDeleteConfirmed} color="primary">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
</div>
<ToastContainer />
</>
  );
};
 
export default LeavesDeclare;
 