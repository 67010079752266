import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import HrSidebar from './HrSidebar';
import Navbar from './HrNavbar';
import Sample from './SampleSide';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Axios from "axios";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
 
const Details = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [data, setData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    if (username) {
      fetchData();
    }
  }, [username, selectedMonth]);
 
  const fetchData = () => {
    let url = `https://api.payroll.yatayati.com/api/Count/Attendance/${username}`;
    if (selectedMonth) {
      url += `?month=${selectedMonth}`;
    }
    Axios.get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  };
 
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on search change
  };
 
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    setCurrentPage(1); // Reset to the first page on month change
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('en-US', { month: 'long' });
    setSelectedMonth(currentMonth);
  }, [])
 
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
 
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
 
  let filteredData = [...data];
 
  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) =>
        item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.EmployeeName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
 
  if (selectedMonth) {
    filteredData = filteredData.filter(
      (item) => item.Month.toLowerCase() === selectedMonth.toLowerCase()
    );
  }
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
     <HrSidebar />
      <Navbar />
      <Sample />
      <div style={{ maxWidth: "100%", padding: "10px", marginLeft: "10px" }}>
        <div className="alien">
          <div className="mars">
            <TextField
              variant="outlined"
              label="EmployeeID Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super.text"
              style={{ marginLeft: "10px" }}
            />
            <Select
            className="month-search"
              value={selectedMonth}
              onChange={handleMonthChange}
              displayEmpty
              style={{ marginLeft: "10px", width: "150px"}}
            >
              <MenuItem value="">All Months</MenuItem>
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell className="super-stickyCell">EmployeeID</TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>Month</TableCell>
                <TableCell>Working Days</TableCell>
                <TableCell>Presented Days</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow
                  key={index}
                  onClick={() => setSelectedEmployee(row)}
                  style={{
                    backgroundColor: selectedEmployee === row ? 'lightgray' : 'white'
                  }}>
                  <TableCell className="super-stickyCell">{row.EmployeeID}</TableCell>
                  <TableCell>{row.EmployeeName}</TableCell>
                  <TableCell>{row.Month}</TableCell>
                  <TableCell>{row.WorkingDays}</TableCell>
                  <TableCell>{row.PresentedDays}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "60px", height: "28px" }}
            >
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </Typography>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
 
export default Details;
 
 