import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
 
const Nav = [
  {
    path: '/SuperAdd',
    name: 'Client Details',
  },
  {
    path: '/SuperDetails',
    name: 'DataGrid',
  },
 
];
 
const Navbar = () => {

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/superhome");
  };


  return (
    <div>
      <div className='hori' style={{ display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
        <div style={{display:"flex", gap:"2rem", padding:"15px"}}>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            activeClassName='super-active'>
            <div className='super-font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
        </div>
        <div style={{marginRight:"40px"}}>
        <button type="button" className="back-button" onClick={handleBack}>
              Back
            </button>
            </div>
      </div>
      
    </div>
  );
};
 
export default Navbar;
 
 
 
 