import React, { useEffect, useState } from 'react';
import { Button, TextField, Checkbox, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AdminSidebar from './AdminSidebar';
import AdminPayroll from './AdminPayroll';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import Axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminNav from './AdminNav';

const Payslip = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, [navigate]);

  const [searchQuery, setSearchQuery] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sentEmployees, setSentEmployees] = useState([]);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const savedSentEmployees = JSON.parse(localStorage.getItem('sentEmployees')) || [];
    setSentEmployees(savedSentEmployees);
    if (username) {
      fetchData(savedSentEmployees);
    }
  }, [username]);

  const fetchData = (savedSentEmployees = []) => {
    setLoading(true);
    Axios.get(`https://api.payroll.yatayati.com/api/all/boo/${username}`)
      .then((response) => {
        const filteredResponseData = response.data.filter(row => !savedSentEmployees.includes(row.EmployeeID));
        setData(filteredResponseData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRows([row]);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('MMMM YYYY');
  };

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevSelectedRows => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter(selectedRow => selectedRow.EmployeeID !== row.EmployeeID);
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRows([]);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleView = () => {
    setAnchorEl(null);
  };

  const handleSendAll = () => {
    if (selectedRows.length === 0) {
      toast.warning("Please select at least one row to send.");
      return;
    }

    toast.warning("Sending Payslips, please wait");

    const rowsToPrint = selectedRows;
    setIsSending(true);

    const promises = rowsToPrint.map(row => {
      const employeeId = row.EmployeeID;
      return Axios.get(`https://api.payroll.yatayati.com/api/send-payslip/${employeeId}`)
        .then(() => {
          return row.EmployeeID;
        })
        .catch((error) => {
          console.error(`Error sending payslip to ${row.EmployeeName}:`, error);
          toast.error(`Failed to send payslip to ${row.EmployeeName}`);
          return null;
        });
    });

    Promise.all(promises)
      .then(sentEmployeeIds => {
        const validSentEmployeeIds = sentEmployeeIds.filter(id => id);
        const newSentEmployees = [...sentEmployees, ...validSentEmployeeIds];
        setSentEmployees(newSentEmployees);
        localStorage.setItem('sentEmployees', JSON.stringify(newSentEmployees));
        const sentEmployeeNames = rowsToPrint
          .filter(row => validSentEmployeeIds.includes(row.EmployeeID))
          .map(row => row.EmployeeName)
          .join(', ');

        toast.success(`Payslips sent successfully to: ${sentEmployeeNames}`);
        fetchData(newSentEmployees);
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  let filteredData = data.filter(row => !sentEmployees.includes(row.EmployeeID));

  if (searchQuery) {
    filteredData = filteredData.filter(item =>
      item.EmployeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <AdminSidebar />
      <AdminPayroll />
      <AdminNav />
      <div style={{ overflowX: 'auto', maxWidth: '100%', padding: '15px' }}>
        <div className='caves' style={{ display: 'flex', justifyContent: "space-between", marginBottom: '20px' }}>
          <div>
            <TextField variant='outlined' label="Name & ID" value={searchQuery} onChange={handleSearchChange} className='text' style={{ marginLeft: '10px' }} />
          </div>
          <div>
            <Button variant="contained" color="primary" size="small" onClick={handleSendAll} disabled={isSending}>
              Send All
            </Button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label='simple table'>
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.length === data.length && data.length > 0}
                    onChange={handleSelectAll}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                  />
                </TableCell>
                <TableCell>Employee ID</TableCell>
                <TableCell>Employee Name</TableCell>
                <TableCell>PaySlip File</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.some(selectedRow => selectedRow.EmployeeID === row.EmployeeID)}
                      onChange={(event) => handleCheckboxChange(event, row)}
                    />
                  </TableCell>
                  <TableCell>{row.EmployeeID}</TableCell>
                  <TableCell>{row.EmployeeName}</TableCell>
                  <TableCell>{row.FileName}</TableCell>
                  <TableCell>{formatDate(row.Date)}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                      <MenuItem onClick={() => handleView(row)}>View</MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}><FaChevronLeft /></Button>
            <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
            <Button disabled={currentPage === totalPages} onClick={handleNextPage}><FaChevronRight /></Button>
          </div>
          <Typography variant="body2">
            {' '}
            <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default Payslip;
