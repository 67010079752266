import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
 
const Nav = [
  {
    path: '/adminCompany',
    name: 'Department',
  },
  {
    path: '/Place',
    name: 'Position',
  },
  {
    path: '/AssetsType',
    name: 'Assets',
  },
  {
    path: '/Availabilty',
    name: 'Shift',
  },
  {
    path: '/TimeOff',
    name: 'Week Off',
  },
  {
    path: '/NoticePeriod',
    name: 'Notice Period',
  },
  {
    path: '/LeavesDeclare',
    name: 'Leave Declaration',
  },
];
 
const Navbar = () => {
  const location = useLocation();
  const navRef = useRef(null);
 
  useEffect(() => {
    const activeLink = navRef.current.querySelector('.active');
    if (activeLink) {
      const { offsetLeft, offsetWidth } = activeLink;
      const { offsetWidth: navWidth, scrollLeft } = navRef.current;
      const activeLinkCenter = offsetLeft + offsetWidth / 2;
      const navCenter = scrollLeft + navWidth / 2;
      navRef.current.scrollTo({
        left: scrollLeft + (activeLinkCenter - navCenter),
        behavior: 'smooth',
      });
    }
  }, [location]);
 
  return (
    <div>
      <div className='hori' ref={navRef} style={{overflow:"auto", padding:"15px"}}>
      <style>
          {`
          .hori::-webkit-scrollbar {
              height: 4px;
            }
            .hori::-webkit-scrollbar-thumb {
              background: #888; 
            }
            .hori::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }
            .font {
              display: inline-block;
              padding: 10px;
            }
        `}
        </style>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            className={({ isActive }) => (isActive ? 'active' : '')}
          >
            <div className='font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
 
export default Navbar;
 
 
 