import React from 'react'
import { NavLink } from 'react-router-dom';

const Nav = [
    {
        path:"/Statement",
        name:"Newly Generated PaySlips"
    },
    {
        path:"/AllPayslips",
        name:"All PaySlips"
    },
];

const AdminNav = () => {
  return (
    <div>
        <div  className='hori'>
        {Nav.map((Navbar, index) => (
            <NavLink
                  key={index}
            to={Navbar.path}
            activeClassName="active" >
            <div className='font'>{Navbar.name}</div> 
            </NavLink>
        ))}
    </div>
    </div>
  )
}

export default AdminNav