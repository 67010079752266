import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import AdminSidebar from "./AdminSidebar";
import AdminPayroll from "./AdminPayroll";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AdminPaying = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
      const token = Cookies.get('token');
         if (!token) {
        navigate('/');
      }
    }, []);
    useEffect(() => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/');
      } else {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
        setRole(decoded.role);
        if (decoded.role !== 'AdminHome') {
          navigate('/');
        }
      }
    }, []);

  const [addComponentOpen, setAddComponentOpen] = useState(false);
  const [newdescription, setNewDescription] = useState("");
  const [newTypeName, setNewTypeName] = useState("");
  const [newComponentID, setNewComponentID] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedpayComponentNames, setSelectedpayComponentNames] = useState([]);
  const [selectedComponentName, setSelectedComponentName] = useState([]);
  const [newComponentType, setNewComponentType] = useState([]);
  const [typeName, setTypeName] = useState("");     
  const [data, setData] = useState([]);


  const fetchDataAll = (() => {
    Axios.get(`https://api.payroll.yatayati.com/api/payComponent/all/${username}`)
    .then((response) => {
      setData(response.data);
      console.log("API Response:", response.data);
    })
    .catch((error) => {
      console.error("Error fetching PayComponent data:", error);
    });
  })

  useEffect(() => {
    fetchDataAll();    
  }, [username]);

  const [filteredComponentNames, setFilteredComponentNames] = useState([]);

  const handleDataTypeChanges = (e) => {
    const selectedType = e.target.value;
    setTypeName(selectedType);
    // Filter component names based on selected component type
    const filteredNames = selectedComponentName.filter(
      (name) => name.ComponentType === selectedType
    );
    setFilteredComponentNames(filteredNames);
  };

  const handleDataTypeChange = (event) => {
    setNewComponentType(event.target.value); 
  };

  // const fetchDataType = () => {
  //   Axios.get(`https://api.payroll.yatayati.com/api/component/${username}`)
  //     .then((response) => {
  //       setFilteredComponentNames(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Component data:", error);
  //       toast.error("Failed to fetch Component data");
  //     });
  // };
  
  // const fetchDataType1 = () => {
  //   Axios.get(`https://api.payroll.yatayati.com/api/component/${username}`)
  //     .then((response) => {
  //       setSelectedComponentName(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching ComponentName data:", error);
  //       toast.error("Failed to fetch ComponentName data");
  //     });
  // };


  // useEffect(() => {
  //   fetchDataType1();
  //   fetchDataType();
  // },[username]);

  const fetchData = async (url, setter) => {
    try {
      const response = await Axios.get(url);
      setter(response.data);
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      toast.error(`Failed to fetch data from ${url}`);
    }
  };
  
  useEffect(() => {
    if (username) {
      fetchData(`https://api.payroll.yatayati.com/api/component/${username}`, setFilteredComponentNames);
      fetchData(`https://api.payroll.yatayati.com/api/component/${username}`, setSelectedComponentName);
    }
  }, [username]);

  const handleOpenAddComponentDialog = () => {
    setAddComponentOpen(true);
  };

  const handleCloseAddComponentDialog = () => {
    setAddComponentOpen(false);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleAddComponent = () => {
    if(!newTypeName || !newComponentType.length ) {
      toast.warning("Please fill in TypeName, ComponentType");
      return;
    }
    Axios.post("https://api.payroll.yatayati.com/api/payComponent/insert", {
      ComponentID: newComponentID,
      TypeName: newTypeName,
      ComponentType: newComponentType,
      Description: newdescription,
      officeId: username,
    })
      .then((response) => {
        console.log("Component Added Successfully:", response.data);
        const newComponent = {
          TypeName: newTypeName,
          ComponentType: newComponentType,
          Description: newdescription,
        };
        setData((preData) => [...data, newComponent]);
        setNewComponentType([]);
        setTypeName("");
        setNewTypeName("");
        setNewDescription("");
        handleCloseAddComponentDialog();
        toast.success("Components added successfully");
        fetchDataAll();
      })
      .catch((error) => {
        console.error("Error adding new Component:", error);
        toast.error("Failed to add Component");
      });
  };

  const handleFormSubmit = () => {
    console.log("Form submitted:", selectedRow);
    Axios.put(
      `https://api.payroll.yatayati.com/api/payComponent/update/${selectedRow.ComponentID}`,
      selectedRow
    )
      .then((response) => {
        handleClose();
        toast.success("Record updated successfully");
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        toast.error("Failed to update record");
      });
  };

  const handleFormChange = (event) => {
    const { id, value } = event.target;
    setSelectedRow((prevSelectedRow) => ({
      ...prevSelectedRow,
      [id]: value,
    }));
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.ComponentID;
      Axios.delete(
        `https://api.payroll.yatayati.com/api/payComponent/delete/${idToDelete}`
      )
        .then((response) => {
          const updatedData = data.filter(
            (item) => item.ComponentID !== idToDelete
          );
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          fetchDataAll();
          handleClose();
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
          toast.error("Failed to delete record");
        });
    }
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <AdminSidebar />
      <AdminPayroll />

      <div
        style={{
          overflowX: "auto",
          maxWidth: "100%",
          padding: "10px",
          marginLeft: "10px",
        }}
      >
        <div className="alien">
          <div className="moon">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenAddComponentDialog}
            >
              + Add PayComponent
            </Button>
            <Dialog
              open={addComponentOpen}
              onClose={handleCloseAddComponentDialog}
            >
              <DialogTitle>Add New PayComponent</DialogTitle>
              <DialogContent>
                
                <TextField
                  label="Payroll Type Name"
                  value={newTypeName}
                  onChange={(e) => setNewTypeName(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  select
                  label="Component Name"
                  value={typeName}
                  onChange={handleDataTypeChanges}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {selectedComponentName
                    .filter(
                      (value, index, self) =>
                        self.findIndex(
                          (item) => item.ComponentType === value.ComponentType
                        ) === index
                    )
                    .map((component, index) => (
                      <MenuItem key={index} value={component.ComponentType}>
                        {component.ComponentType}
                      </MenuItem>
                    ))}
                </TextField>

                <TextField
                  select
                  label="Pay Component Type"
                  value={newComponentType}
                  onChange={handleDataTypeChange}
                  SelectProps={{
                    multiple: true,
                    renderValue: (selected) => selected.join(", "),
                  }}
                  fullWidth
                  margin="normal"
                  disabled={!typeName} 
                >
                  {typeName ? (
                    filteredComponentNames.map((component) => (
                      <MenuItem
                        key={component.ComponentName}
                        value={component.ComponentName}
                      >
                        {component.ComponentName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      Please select a component type first
                    </MenuItem>
                  )}
                </TextField>

                <TextField
                  label="Description"
                  value={newdescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAddComponentDialog}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleAddComponent} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                {/* <TableCell>Component ID</TableCell> */}
                <TableCell>Payroll Type Name</TableCell>
                <TableCell>Component Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row) => (
                <TableRow key={row}>
                  {/* <TableCell>{row.ComponentID}</TableCell> */}
                  <TableCell>{row.TypeName}</TableCell>
                  <TableCell>{row.PayComponents}</TableCell>
                  <TableCell>{row.Description || 'None'}</TableCell>

                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem>
              <span
                style={{ textDecoration: "none" }}
                data-bs-toggle="offcanvas"
                data-bs-target="#editOffcanvas"
                aria-controls="editOffcanvas"
              >
                Edit
              </span>
              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="editOffcanvas"
                aria-labelledby="editOffcanvasLabel"
                style={{ width: "400px", overflowY: "auto" }}
              >
                <div className="offcanvas-header">
                  <h5 className="offcanvas-title" id="editOffcanvasLabel">
                    Edit Details
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label htmlFor="ComponentID" className="form-label">
                        Component ID:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ComponentID"
                        value={selectedRow.ComponentID}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="TypeName" className="form-label">
                        Payroll Type Name:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="TypeName"
                        value={selectedRow.TypeName}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="ComponentType" className="form-label">
                        Component Type:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="PayComponents"
                        value={selectedRow.PayComponents}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Description" className="form-label">
                        Description:
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Description"
                        value={selectedRow.Description}
                        onChange={handleFormChange}
                      />
                    </div>
                    <div className="d-flex">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        data-bs-dismiss="offcanvas"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </MenuItem> */}
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default AdminPaying;
