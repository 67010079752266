import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import HrSidebar from "./HrSidebar";
import HrMy from "./profileNav";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const MyLeave = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState([]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "HrHome") {
        navigate("/");
      }
    }
  }, []);

  const fetchnameData = async () => {
    try {
      const response = await Axios.get(
        `https://api.payroll.yatayati.com/loginuser/${username}`
      );
      // const loggedInUser = response.data.user;
      const sortedData = response.data.user.sort((a, b) => new Date(a.RequestedOn) - new Date(b.RequestedOn));
      setUser(sortedData);
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchnameData();
    }
  }, [username]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [viewPopupOpen, setViewPopupOpen] = useState(false);
  const [viewRowDetails, setViewRowDetails] = useState(null);
  const [addLeaveOpen, setAddLeaveOpen] = useState(false);
  const [newEmployeeName, setNewEmployeeName] = useState("");
  const [newStartDate, setNewStartDate] = useState("");
  const [newEndDate, setNewEndDate] = useState("");
  const [newComment, setNewComment] = useState("");
  const [newLeaveType, setNewLeaveType] = useState("");
  const [data, setData] = useState([]);
  const [leavedata, setLeaveData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(); 

  useEffect(() => {
    fetchDataLeave();
  }, []);

  const handleLeaveTypeChange = (e) => {
    const selectedType = e.target.value;
    setNewLeaveType(selectedType);
  };

  const fetchDataLeave = () => {
    Axios.get(`https://api.payroll.yatayati.com/api/Leavechange/type`)
      .then((response) => {
        setLeaveData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Leave data:", error);
        toast.error("Failed to fetch Leave data");
      });
  };

  const handleViewRow = (row) => {
    setViewRowDetails(row);
    setViewPopupOpen(true);
  };

  const handleCloseViewPopup = () => {
    setViewPopupOpen(false);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(user.length / rowsPerPage)));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleOpenAddLeaveDialog = () => {
    setAddLeaveOpen(true);
  };

  const handleCloseAddLeaveDialog = () => {
    setAddLeaveOpen(false);
  };

  useEffect(() => {
    Axios.get("https://api.payroll.yatayati.com/api/For").then((response) => {
      setData(response.data);
    });
  }, []);

  const handleAddLeave = () => {
    if (!newStartDate || !newEndDate || !newLeaveType) {
      toast.warning('Please fill in all mandatory fields: Start Date, End Date, and Leave Type.');
      return;
    }
    toast.info('Sending Leave Request...');
    handleCloseAddLeaveDialog();

    const startDate = new Date(newStartDate);
    const endDate = new Date(newEndDate);
    const leaveDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  
    const currentDate = new Date().toISOString().split('T')[0]; 
  
    Axios.post("https://api.payroll.yatayati.com/api/Leave/insert", {
      EmployeeId: username,
      EmployeeName: newEmployeeName,
      StartDate: newStartDate,
      EndDate: newEndDate,
      LeaveType: newLeaveType,
      Comment: newComment,
      Count: leaveDays,
      CurrentDate: currentDate,
      officeID: username,
    })
      .then((response) => {
        toast.success("Sent Successfully");
        console.log("Leave added successfully:", response.data);
        const newLeave = {
          id: response.data.id,
          EmployeeId: username,
          EmployeeName: newEmployeeName,
          StartDate: newStartDate,
          EndDate: newEndDate,
          LeaveType: newLeaveType,
          LeaveStatus: "Pending",
          Comment: newComment,
          Count: leaveDays,
          CurrentDate: currentDate,
          officeID: username,
        };
        setData([...data, newLeave]);
        setNewEmployeeName("");
        setNewStartDate("");
        setNewEndDate("");
        setNewLeaveType("");
        setNewComment("");
        fetchnameData();
      })
      .catch((error) => {
        console.error("Error adding new leave:", error);
      });
  };
  

  let filteredData = [...user];

  if (selectedMonth) {
    const [year, month] = selectedMonth.split("-");
    filteredData = filteredData.filter(
      item => moment(item.Date).format("YYYY") === year &&
              moment(item.Date).format("MM") === month
    );
  }
  

  const displayedRows = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
  .sort((a, b) => new Date(a.Date) - new Date(b.Date));
  const totalPages = Math.ceil(user.length / rowsPerPage);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <>
      <HrSidebar />
      <HrMy/>
      <div className="emp-roll">
        <div className="emp-electro">
          <h3 className="header1">Leave Management</h3>

          <div>
            <div
              style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
              <div
                className="emp-typo"
                style={{ display:"flex", justifyContent: "space-between" }}>

                <div style={{marginTop:"15px"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleOpenAddLeaveDialog}>
                    Send Leave Request
                  </Button>
                  <Dialog
                    open={addLeaveOpen}
                    onClose={handleCloseAddLeaveDialog}
                  >
                    <DialogTitle>Leave Request</DialogTitle>
                    <DialogContent>
                      <TextField
                        label="Employee ID"
                        value={username}
                        fullWidth
                        margin="normal"
                        disabled
                      />
                      <TextField
                        label="From Date"
                        value={newStartDate}
                        onChange={(e) => setNewStartDate(e.target.value)}
                        fullWidth
                        margin="normal"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        label="TO Date"
                        value={newEndDate}
                        onChange={(e) => setNewEndDate(e.target.value)}
                        fullWidth
                        margin="normal"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        select
                        label="Leave Type"
                        value={newLeaveType}
                        onChange={handleLeaveTypeChange}
                        fullWidth
                        margin="normal"
                      >
                        {leavedata.map((Leave, index) => (
                          <MenuItem key={index} value={Leave.LeaveType}>
                            {Leave.LeaveType}
                          </MenuItem>
                        ))}
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                      <TextField
                        label="Comment"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        fullWidth
                        margin="normal"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleCloseAddLeaveDialog}
                        color="secondary"
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleAddLeave} color="primary">
                        Send
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div style={{marginTop:"12px"}}>
                <TextField
            variant='outlined'
            label="Month"
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
                </div>
              </div>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
                    <TableRow>
                      <TableCell className="super-stickyCell">Requested on</TableCell>
                      <TableCell>From Date</TableCell>
                      <TableCell>To Date</TableCell>
                      <TableCell>Count Days</TableCell>
                      <TableCell>Leave Type</TableCell>
                      <TableCell>Leave Status</TableCell>
                      <TableCell>Comment</TableCell>
                      {/* <TableCell>Action</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedRows.map((userData) => (
                      <TableRow key={userData.EmployeeID}>
                        <TableCell className="super-stickyCell">
                          {moment(userData.RequestedOn).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(userData.StartDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>
                          {moment(userData.EndDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell>{userData.TotalCount}</TableCell>
                        <TableCell>{userData.LeaveType}</TableCell>

                        <TableCell>{userData.LeaveStatus || 'Pending'}</TableCell>
                        <TableCell>{userData.Comments || 'None'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <div>
                  <Button disabled={currentPage === 1} onClick={handlePrevPage}>
                    <FaChevronLeft />
                  </Button>
                  <span style={{ margin: "0 10px" }}>
                    Page {currentPage} of {totalPages}
                  </span>
                  <Button
                    disabled={currentPage === totalPages}
                    onClick={handleNextPage}
                  >
                    <FaChevronRight />
                  </Button>
                </div>
                <Typography variant="body2">
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                    style={{ width: "40px", height: "28px" }}
                  >
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                  </select>
                </Typography>
              </div>
              {selectedRow && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleViewRow(selectedRow)}>
                    View
                  </MenuItem>
                </Menu>
              )}
              <Dialog open={viewPopupOpen} onClose={handleCloseViewPopup}>
                <DialogTitle>View Row Details</DialogTitle>
                <DialogContent>
                  {viewRowDetails && (
                    <div>
                      <Typography>ID: {viewRowDetails.EmployeeId}</Typography>
                      <Typography>
                        Name: {viewRowDetails.EmployeeName}
                      </Typography>
                      <Typography>
                        Leave Type: {viewRowDetails.LeaveType}
                      </Typography>
                      <Typography>
                        Start Date: {viewRowDetails.StartDate}
                      </Typography>
                      <Typography>
                        End Date: {viewRowDetails.EndDate}
                      </Typography>
                      <Typography>
                        Status: {viewRowDetails.LeaveStatus}
                      </Typography>
                      <Typography>
                        Comments: {viewRowDetails.Comments}
                      </Typography>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseViewPopup} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default MyLeave;
