import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useState } from "react";

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    const { state } = location;

    if (!token || !state || !state.username || !state.role) {
      navigate("/");
    } else {
      setUsername(state.username);
      setRole(state.role);
      console.log(state.username, state.role);
    }
  }, [location, navigate]);

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/");
  };

  return (
    <div>
      
      <h2>Welcome to the Admin Page!</h2>
      <p>Username: {username}</p>
      <p>Role: {role}</p>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Admin;
