import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Nav = [
  { path: '/hrOrganization', name: 'Department' },
  { path: '/hrPosition', name: 'Position' },
  { path: '/hrAssets', name: 'Assets' },
  { path: '/hrShift', name: 'Shift' },
  { path: '/hrWeekOff', name: 'Week Off' },
  { path: '/hrNotice', name: 'Notice Period' },
  { path: '/HRLeavesDeclare', name: 'LeavesDeclare' },
];

const Navbar = () => {
  const location = useLocation();
  const navRef = useRef(null);
 
  useEffect(() => {
    const activeLink = navRef.current.querySelector('.active');
    if (activeLink) {
      const { offsetLeft, offsetWidth } = activeLink;
      const { offsetWidth: navWidth, scrollLeft } = navRef.current;
      const activeLinkCenter = offsetLeft + offsetWidth / 2;
      const navCenter = scrollLeft + navWidth / 2;
      navRef.current.scrollTo({
        left: scrollLeft + (activeLinkCenter - navCenter),
        behavior: 'smooth',
      });
    }
  }, [location]);

  return (
    <div>
      <div className='hori' ref={navRef} style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
        <style>
          {`
            .hori::-webkit-scrollbar {
              height: 4px;
            }
            .hori::-webkit-scrollbar-thumb {
              background: #888; 
            }
            .hori::-webkit-scrollbar-thumb:hover {
              background: #555; 
            }
            .font {
              display: inline-block;
              padding: 10px;
            }
            // .active {
            //   font-weight: bold;
            //   color: green;
            // }
          `}
        </style>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            className={({ isActive }) => (isActive ? 'active' : '')}>
            <div className='font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Navbar;
