import React from 'react';
import { NavLink } from 'react-router-dom';
 
const Nav = [
  {
    path: '/AdminBasicDetails',
    name: 'Adding Employee',
  },
 
  {
    path: '/Bio',
    name: 'All Details',
  },
];
 
const Navbar = () => {
  return (
    <div>
      <div className='hori' style={{ display: 'flex', alignItems: 'center', padding:"10px" }}>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            activeClassName='active'>
            <div className='font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
       
      </div>
    </div>
  );
};
 
export default Navbar;