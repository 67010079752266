import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Menu,
  MenuItem,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { FaEllipsisV, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import Navbar from "./HrWeek";
import "./HrLeaves.css";
import HrSidebar from "./HrSidebar";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const Assets = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "HrHome") {
        navigate("/");
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditForm, setIsEditForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [addAssetOpen, setAddAssetOpen] = useState(false);
  const [newAssetName, setNewAssetName] = useState("");
  const [newAssetType, setNewAssetType] = useState("");
  const [newAssetCount, setNewAssetCount] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newAssetID, setNewAssetID] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);

  const [data, setData] = useState([]);

  const fetchData = (() => {
    Axios.get(`https://api.payroll.yatayati.com/api/Assets/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Assets data:", error);
      });
  })

  useEffect(() => {
    fetchData();
  }, [username]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleFormSubmit = (event) => {
 event.preventDefault();
    Axios.put(
      `https://api.payroll.yatayati.com/api/Assets/update/${editedRow.id}`,
      editedRow
    )
      .then((response) => {
        fetchData();
        handleClose();
        setEditDialogOpen(false);
        setEditedRow(null);
        toast.success("Record updated successfully");
        setData((prevData) =>
          prevData.map((item) => (item.id === editedRow.id ? editedRow : item))
        );
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        toast.error("Failed to update record");
      });
  };

  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditOpen = (row) => {
    setEditedRow({...row,
      Date: moment(row.Date).format("YYYY-MM-DD"),
    });
    setEditDialogOpen(true);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.id;
      Axios.delete(`https://api.payroll.yatayati.com/api/Asset/delete/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter((item) => item.id !== idToDelete);
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          fetchData();
          handleClose();
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
          toast.error("Failed to delete record");
        });
    }
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleOpenAddAssetDialog = () => {
    setAddAssetOpen(true);
  };

  const handleCloseAddAssetDialog = () => {
    setAddAssetOpen(false);
  };

  const handleAddAsset = () => {
    if(!newAssetType || !newAssetName || !newDate || !newAssetCount ) {
      toast.warning("Please fill required fields");
      return;
    }
    console.log("Adding Assets....", {
      id: newAssetID,
      AssetType: newAssetType,
      AssetName: newAssetName,
      Date: newDate,
      Count: newAssetCount,
      Description: newDescription,
      officeId: username,
    });
    Axios.post("https://api.payroll.yatayati.com/api/Asset/post", {
      id: newAssetID,
      AssetType: newAssetType,
      AssetName: newAssetName,
      Date: newDate,
      Count: newAssetCount,
      Description: newDescription,
      officeId: username,
    })
      .then((response) => {
        console.log("Asset added Successfully:", response.data);
        const newAsset = {
          id: newAssetID,
          AssetType: newAssetType,
          AssetName: newAssetName,
          Date: newDate,
          Count: newAssetCount,
          Description: newDescription,
          officeId: username,
        };
        setData((preData) => [...data, newAsset]);
        setNewAssetType("");
        setNewAssetName("");
        setNewDate("");
        setNewAssetCount("");
        setNewDescription("");
        handleCloseAddAssetDialog();
        fetchData();
        toast.success("Assets added successfully");
      })
      .catch((error) => {
        console.error("Error adding new Assets:", error);
        toast.error("Failed to add Assets");
      });
  };

  let filteredData = [...data];

  if (selectedDate) {
    filteredData = filteredData.filter((item) =>
      moment(item.date).isSame(selectedDate, "day")
    );
  }

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) =>
        item.AssetName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.AssetType.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <HrSidebar />
      <Navbar />

      <div
      style={{margin:"20px"}}>
        <div className="alien">
          <div className="mars">
            <TextField
              variant="outlined"
              label="Name & Type"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super.text"
              style={{ marginLeft: "10px" }}
            />
          </div>

          <div className="moon">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenAddAssetDialog}
            >
              + Add Asset Type
            </Button>
            <Dialog open={addAssetOpen} onClose={handleCloseAddAssetDialog}>
              <DialogTitle>Add New Asset</DialogTitle>
              <DialogContent>
                <TextField
                  label="Asset Type"
                  value={newAssetType}
                  onChange={(e) => setNewAssetType(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Asset Name"
                  value={newAssetName}
                  onChange={(e) => setNewAssetName(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  type="date"
                  value={newDate}
                  onChange={(e) => setNewDate(e.target.value)}
                  fullWidth
                  margin="normal"
                />

                <TextField
                  label="Asset Count"
                  value={newAssetCount}
                  onChange={(e) => setNewAssetCount(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={newDescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAddAssetDialog} color="secondary">
                  Cancel
                </Button>
                <Button onClick={handleAddAsset} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell>Name of the Asset Type</TableCell>
                <TableCell>Asset Name</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Assets Count</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.AssetType}</TableCell>
                  <TableCell>{row.AssetName}</TableCell>
                  <TableCell>{moment(row.Date).format("DD-MM-YYYY")}</TableCell>
                  <TableCell>{row.Count}</TableCell>
                  <TableCell>{row.Description ? row.Description : 'None'}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem> 
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <TextField
                id="AssetType"
                label="Name of the Asset Type:"
                value={editedRow ? editedRow.AssetType : ""}
                onChange={(e) => handleEditFormChange(e, "AssetType")}
                fullWidth
                margin="normal"
              />
              <TextField
                id="AssetName"
                label="Asset Name:"
                value={editedRow ? editedRow.AssetName : ""}
                onChange={(e) => handleEditFormChange(e, "AssetName")}
                fullWidth
                margin="normal"
              />
 
              <TextField
                id="Date"
                label="Date:"
                type="date"
                value={editedRow ? editedRow.Date : ""}
                onChange={(e) => handleEditFormChange(e, "Date")}
                fullWidth
                margin="normal"
              />
              <TextField
                id="Count"
                label="Asset Count:"
                value={editedRow ? editedRow.Count : ""}
                onChange={(e) => handleEditFormChange(e, "Count")}
                fullWidth
                margin="normal"
              />
 
              <TextField
                id="Description"
                label="Description:"
                value={editedRow ? editedRow.Description : ""}
                onChange={(e) => handleEditFormChange(e, "Description")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default Assets;
