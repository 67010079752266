import React from 'react';
import { NavLink } from 'react-router-dom';
// import './AdminPayroll.css';

const Nav = [
    {
        path:"/EmpPayHeader",
        name:"Payroll"
    },
    {
        path:"/FileDownload",
        name:"FileDownload"
    },
];

const EmpNavbar = () => {
  return (
    <div  className='hori'>
        {Nav.map((Navbar, index) => (
            <NavLink
                  key={index}
            to={Navbar.path}
            activeClassName="active" >
            <div className='font'>{Navbar.name}</div> 
            </NavLink>
        ))}
    </div>
  )
}

export default EmpNavbar