import React, { useEffect, useState } from "react";
import Navbar from "./AdminNavbar";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Bio = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, []);

  const fetchNameData = async () => {
    try {
      const response = await Axios.get(
        `https://api.payroll.yatayati.com/leaf/${username}`
      );
      const data = response.data;
      if (data.success) {
        setEmployees(data.employees);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {

    if (username) {
      fetchNameData();
    }
  }, [username]);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [positionData, setPositionData] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [weekOffData, setWeekOffData] = useState([]);
  const [noticePeriodData, setNoticePeriodData] = useState([]);
  const [payrollTypes, setPayrollTypes] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    Axios.get("https://api.payroll.yatayati.com/datagrid/Employee")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

useEffect(() => {
    Axios.get("https://api.payroll.yatayati.com/department/none")
      .then((response) => {
        setDepartmentData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching department data:", error);
      });

    Axios.get("https://api.payroll.yatayati.com/api/position/type")
      .then((response) => {
        setPositionData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching position data:", error);
      });

    Axios.get("https://api.payroll.yatayati.com/api/shift/type")
      .then((response) => {
        setShiftData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching shift data:", error);
      });

    Axios.get("https://api.payroll.yatayati.com/api/weekoff/type")
      .then((response) => {
        setWeekOffData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching week off data:", error);
      });

    Axios.get("https://api.payroll.yatayati.com/api/Notice/type")
      .then((response) => {
        setNoticePeriodData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching notice period data:", error);
      });
      Axios.get("https://api.payroll.yatayati.com/api/data/type")
      .then((response) => {
        setPayrollTypes(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Payroll data:", error);
      });
  }, []);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handlePrePage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleEdit = () => {
    setEditRowData(selectedRow);
    setEditDialogOpen(true);
    handleClose();
  };

  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setEditRowData(null);
  };

  const handleEditConfirmed = () => {
    if (editRowData) {
      Axios.put(`https://api.payroll.yatayati.com/api/updateEmployee/${editRowData.EmployeeID}`, editRowData)
        .then((response) => {
          const updatedData = data.map((item) =>
            item.id === editRowData.EmployeeID ? editRowData : item
          );
          setData(updatedData);
          setEditDialogOpen(false);
          setEditRowData(null);
          toast.success("Updated Successfully");
          fetchNameData();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    }
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.EmployeeID;
      Axios.delete(`https://api.payroll.yatayati.com/api/employee/delete/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter((item) => item.EmployeeID !== idToDelete);
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          handleClose();
          toast.success("Record Deleted Successfully");
          fetchNameData();
        })
        .catch((error) => {
          console.error("Error deleting data:", error);
        });
    }
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  let filteredData = [...employees];

  if (selectedDate) {
    filteredData = filteredData.filter((item) =>
      moment(item.date).isSame(selectedDate, "day")
    );
  }

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <AdminSidebar />
      <Navbar />

      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="typo">
        <div className="caves">
            <TextField
              variant="outlined"
              label="EmployeeID & Name"
              value={searchQuery}
              onChange={handleSearchChange}
              className="text"
              style={{ marginLeft: "10px" }}
            ></TextField>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell className="super-stickyCell">Employee ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Middle Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Gender</TableCell>
                <TableCell>DOB</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Postal Code</TableCell>
                <TableCell>Reporting</TableCell>
                <TableCell>Reporting To</TableCell>
                <TableCell>Job Position</TableCell>
                <TableCell>Job Department</TableCell>
                <TableCell>Shifts</TableCell>
                <TableCell>Weekly Offs</TableCell>
                <TableCell>Notice Period</TableCell>
                <TableCell>Basic/Month</TableCell>
                {/* <TableCell>Payroll Type</TableCell> */}
                <TableCell>Total Salary</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    className="super-stickyCell">
                    {row.EmployeeID}
                  </TableCell>
                  <TableCell>{row.FirstName}</TableCell>
                  <TableCell>{row.MiddleName}</TableCell>
                  <TableCell>{row.LastName}</TableCell>
                  <TableCell>{row.Gender}</TableCell>
                  <TableCell>
                    {moment(row.DateOfBirth).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>{row.Email}</TableCell>
                  <TableCell>{row.PhoneNumber}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.State}</TableCell>
                  <TableCell>{row.PostalCode}</TableCell>
                  <TableCell>{row.Reporting}</TableCell>
                  <TableCell>{row.Repoting_to}</TableCell>
                  <TableCell>{row.JobTitle}</TableCell>
                  <TableCell>{row.JobDepartment}</TableCell>
                  <TableCell>{row.Shifts}</TableCell>
                  <TableCell>{row.WeeklyOffs}</TableCell>
                  <TableCell>{row.NoticePeriod}</TableCell>
                  <TableCell>{row.basicPerMonth}</TableCell>
                  {/* <TableCell>{row.payroll}</TableCell> */}
                  <TableCell>{row.TotalSalary}</TableCell>
                  <TableCell>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Button onClick={(event) => handleClick(event, row)}>
                        <MoreHorizIcon />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrePage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px", color: "black" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {""}
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Edit Record</DialogTitle>
          <DialogContent>
            <TextField
              label="First Name"
              value={editRowData ? editRowData.FirstName : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  FirstName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Middle Name"
              value={editRowData ? editRowData.MiddleName : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  MiddleName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Last Name"
              value={editRowData ? editRowData.LastName : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  LastName: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <Select
              label="Gender"
              value={editRowData ? editRowData.Gender : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Gender: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>

            <TextField
              label="Date of Birth"
              value={
                editRowData
                  ? moment(editRowData.DateOfBirth).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  DateOfBirth: e.target.value,
                }))
              }
              type="date"
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Email"
              value={editRowData ? editRowData.Email : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Email: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Phone Number"
              value={editRowData ? editRowData.PhoneNumber : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  PhoneNumber: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="City"
              value={editRowData ? editRowData.city : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  City: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="State"
              value={editRowData ? editRowData.State : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  State: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="PostalCode"
              value={editRowData ? editRowData.PostalCode : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  PostalCode: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Reporting"
              value={editRowData ? editRowData.Reporting : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Reporting: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <TextField
              label="Repoting_to"
              value={editRowData ? editRowData.Repoting_to : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  Repoting_to: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            />
            <Select
              label="JobTitle"
              value={editRowData ? editRowData.JobTitle : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  JobTitle: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            >
               {positionData.map((option) => (
                    <MenuItem key={option.PositionName} value={option.PositionName}>
                      {option.PositionName}
                    </MenuItem>
               ))}
            </Select>


          <Select
            label="Department"
            value={editRowData ? editRowData.JobDepartment : ""}
            onChange={(e) =>
              setEditRowData((prevData) => ({
                ...prevData,
                JobDepartment: e.target.value,
              }))
            }
            fullWidth
            style={{ marginBottom: "20px" }}
          >
            {departmentData.map((option) => (
              <MenuItem key={option.DepartmentName} value={option.DepartmentName}>
                {option.DepartmentName}
              </MenuItem>
            ))}
          </Select>


          <Select
  label="Shifts"
  value={editRowData ? editRowData.Shifts : ""}
  onChange={(e) =>
    setEditRowData((prevData) => ({
      ...prevData,
      Shifts: e.target.value,
    }))
  }
  fullWidth
  style={{ marginBottom: "20px" }}
>
  {shiftData.map((shift) => (
    <MenuItem key={shift.ShiftName} value={shift.ShiftName}>
      {shift.ShiftName}
    </MenuItem>
  ))}
</Select>

<Select
  label="Weekly Offs"
  value={editRowData ? editRowData.WeeklyOffs : ""}
  onChange={(e) =>
    setEditRowData((prevData) => ({
      ...prevData,
      WeeklyOffs: e.target.value,
    }))
  }
  fullWidth
  style={{ marginBottom: "20px" }}
>
  {weekOffData.map((weekOff) => (
    <MenuItem key={weekOff.Day} value={weekOff.Day}>
      {weekOff.Day}
    </MenuItem>
  ))}
</Select>

<Select
  label="Notice Period"
  value={editRowData ? editRowData.NoticePeriod : ""}
  onChange={(e) =>
    setEditRowData((prevData) => ({
      ...prevData,
      NoticePeriod: e.target.value,
    }))
  }
  fullWidth
  style={{ marginBottom: "20px" }}
>
  {noticePeriodData.map((noticePeriod) => (
    <MenuItem key={noticePeriod.NoticePeriodDuration} value={noticePeriod.NoticePeriodDuration}>
      {noticePeriod.NoticePeriodDuration}
    </MenuItem>
  ))}
</Select>

            {/* <TextField
              label="BasicPerMonth"
              value={editRowData ? editRowData.basicPerMonth : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  basicPerMonth: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}

            {/* <Select
        label="Payroll Type"
        value={editRowData ? editRowData.payroll : ""}
        onChange={(e) =>
          setEditRowData((prevData) => ({
            ...prevData,
            payroll: e.target.value,
          }))
        }
        fullWidth
        style={{ marginBottom: "20px" }}
      >
        {payrollTypes.map((type) => (
          <MenuItem key={type.TypeName} value={type.PayComponents}>
            {type.TypeName}
          </MenuItem>
        ))}
      </Select> */}
{/* 
            <TextField
              label="TotalSalary"
              value={editRowData ? editRowData.totalSalary : ""}
              onChange={(e) =>
                setEditRowData((prevData) => ({
                  ...prevData,
                  totalSalary: e.target.value,
                }))
              }
              fullWidth
              style={{ marginBottom: "20px" }}
            /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleEditConfirmed} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer/>
      </div>
    </>
  );
};

export default Bio;
