import React from 'react';
import { NavLink } from 'react-router-dom';
 
const Nav = [
  {
    path: '/HrLeaveData',
    name: 'Pending Leaves',
  },
  {
    path: '/ApproveLeave',
    name: 'All Leaves',
  },
 
];
 
const LeaveReqNav = () => {

  return (
      <div className='hori' style={{ display: 'flex', alignItems: 'center', justifyContent:"space-between"}}>
        <div style={{display:"flex", gap:"2rem", padding:"15px"}}>
        {Nav.map((NavbarItem, index) => (
          <NavLink
            key={index}
            to={NavbarItem.path}
            activeClassName='super-active'>
            <div className='super-font'>{NavbarItem.name}</div>
          </NavLink>
        ))}
        </div>
      </div>
  );
};
 
export default LeaveReqNav;
 
 
 
 