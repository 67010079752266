import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  AreaChart
} from "recharts";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminSidebar from "./AdminSidebar";
import "./AdminDashboard.css";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import Axios from "axios";
import AdminGender from "../../Hr/components/HR/Gender";
 
function Home() {
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
   
    const token = Cookies.get("token");
 
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
 
      setUsername(decoded.username);
 
      setRole(decoded.role);
 
      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, []);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await Axios.get("https://api.payroll.yatayati.com/AdminHome", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
 
    fetchUserData();
  }, [navigate]);
 
  const [data, setData] = useState([]);
  const [type, setType] = useState([]);
  const [payComponents, setPayComponents] = useState([]);
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [totalMonthlySalary, setTotalMonthlySalary] = useState(0);
  const [upcomingHoliday, setUpcomingHoliday] = useState({});
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.yatayati.com/api/employee-department/${username}`
        );
        setType(response.data);
      } catch (error) {
        console.error("Error fetching employee department data:", error);
      }
    };
 
    const fetchPayComponents = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.yatayati.com/api/pay-components/${username}`
        );
        setPayComponents(response.data);
      } catch (error) {
        console.error("Error fetching pay components data:", error);
      }
    };
 
    const fetchEmployeeCount = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.yatayati.com/api/employee-count/${username}`
        );
        setTotalEmployeeCount(response.data.totalEmployeeCount);
      } catch (error) {
        console.error("Error fetching employee count:", error);
      }
    };
 
    const fetchTotalMonthlySalary = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.yatayati.com/api/total-monthly-salary/${username}`
        );
        setTotalMonthlySalary(response.data.totalBonus);
      } catch (error) {
        console.error("Error fetching total monthly salary:", error);
      }
    };
 
    const fetchUpcomingHoliday = async () => {
      try {
        const response = await axios.get(
          `https://api.payroll.yatayati.com/api/upcoming-holiday/${username}`
        );
        setUpcomingHoliday(response.data);
      } catch (error) {
        console.error("Error fetching upcoming holiday:", error);
      }
    };
 
    fetchData();
    fetchPayComponents();
    fetchEmployeeCount();
    fetchTotalMonthlySalary();
    fetchUpcomingHoliday();
  }, [username]);


  const generateAllMonthsForCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; 
    const months = [];
  
    for (let month = 1; month <= currentMonth; month++) {
      const monthString = month < 10 ? `0${month}` : `${month}`;
      months.push(`${currentYear}-${monthString}`);
    }
  
    return months;
  };
  
  // Function to merge the fetched data with all possible months
  const mergeDataWithAllMonths = (data) => {
    const allMonths = generateAllMonthsForCurrentYear();
    const dataMap = new Map(data.map(item => [item.Month, item.TotalMonthlySalary]));
  
    return allMonths.map(month => ({
      Month: month,
      TotalMonthlySalary: dataMap.get(month) || 0
    }));
  };
  
  const Chart = ({ data }) => (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 50,
          right: 30,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Month" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="TotalMonthlySalary" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
 
  useEffect(() => {
    axios.get(`https://api.payroll.yatayati.com/all/total-monthly-salary/${username}`)
      .then(response => {
        const mergedData = mergeDataWithAllMonths(response.data);
        setData(mergedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [username]);
 
  return (
    <>
      <AdminSidebar />
      <main className="main-container">
        <div className="main-title">
          <h3>ADMIN DASHBOARD</h3>
        </div>
 
        <div className="main-cards">
          <div className="card">
            <div className="card-inner">
              <h5>Total Income</h5>
            </div>
            <h4>0</h4>
          </div>
          <div className="card">
            <div className="card-inner">
              <h5>Number of Employees</h5>
            </div>
            <h4>{totalEmployeeCount}</h4>
          </div>
          <div className="card">
  <div className="card-inner">
    <h5>Total Bonus</h5>
  </div>
  <h4>{totalMonthlySalary || 0}</h4>
</div>
          <div className="card">
            <div className="card-inner">
              <h5>Upcoming Holiday </h5>
            </div>
            <h4> {upcomingHoliday.HolidayName}</h4>
          </div>
        </div>
 
        <div className="charts">
          <div className="chart-container">
            <div className="bottam">
              <h4
                className="text-primary shadow-sm p-2 bg-body rounded"
                style={{ marginTop: "-50px" }}>
                Employee Count
              </h4>
              <ResponsiveContainer width="100%" height={350}>
                <BarChart
                  width={500}
                  height={300}
                  data={type}
                  margin={{
                    top: 30,
                    right: 30,
                    left: 20,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="EmployeeCount" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="chart-container">
          <div className="bottam">
          <h4 className="text-primary shadow-sm p-6 bg-body rounded"
          style={{marginTop:"-50px"}}>
                Payroll Expenses Monthly
              </h4>
              <Chart data={data} />
              </div>
          </div>
        </div>
 
        <div className="data-gender-container">
          <div className="chart-container">
          <div className="bottam">
          <h4 className="text-primary shadow-sm p-6 bg-body rounded"
          style={{marginTop:"-50px"}}>
                Gender
              </h4>
            <AdminGender />
            </div>
          </div>

          <div className="data-grid">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th >
                    Component Name
                  </th>
                  <th>
                    Component Value
                  </th>
                  <th>
                    Value Type
                  </th>
                </tr>
              </thead>
              <tbody>
                {payComponents.map((component, index) => (
                  <tr key={index}>
                    <td>{component.ComponentName}</td>
                    <td>{component.ComponentValue}</td>
                    <td>{component.ValueType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </main>
    </>
  );
}
 
export default Home;
 