import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsEmojiSunglasses, BsRepeat, BsPersonCircle, BsJustify } from 'react-icons/bs';
import { MdLogout, MdHome } from 'react-icons/md';
import { BsEscape } from 'react-icons/bs';
// import { GiReceiveMoney } from 'react-icons/gi';
import { TbListDetails } from "react-icons/tb";
import Cookies from 'js-cookie';


function Sidebar() {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpenSidebarToggle(false);
    }
  };

  const handleItemClick = () => {
    setOpenSidebarToggle(false);
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setShowLogoutConfirmation(true); 
  };

  const handleConfirmLogout = () => {
    setShowLogoutConfirmation(false); 
    Cookies.remove('token');
    navigate('/');
  };

  const handleCloseLogoutModal = () => {
    setShowLogoutConfirmation(false); 
  };

  return (
    <>
      <aside
        id="sidebar"
        ref={sidebarRef}
        className={`${openSidebarToggle ? 'sidebar-responsive' : ''} ${
          !openSidebarToggle ? 'sidebar-closed' : ''
        }`}
      >
        <div className="sidebar-title">
          <div className="sidebar-brand">
            <TbListDetails className="icon_header" /> Admin
          </div>
        </div>

        <ul className="sidebar-list">
          <Link to="/AdminHome" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <MdHome className="icon" /> Dashboard
            </li>
          </Link>

          <Link to="/AdminBasicDetails" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsPeopleFill className="icon" /> Employee
            </li>
          </Link>

          <Link to="/adminTable" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsFillGrid3X3GapFill className="icon" /> Attendance
            </li>
          </Link>

          <Link to="/adminPaid" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEscape className="icon" /> Leave
            </li>
          </Link>

          <Link to="/adminPaying" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsListCheck className="icon" /> Payroll
            </li>
          </Link>

          <Link to="/adminDayOff" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEmojiSunglasses className="icon" /> Holidays
            </li>
          </Link>

          <Link to="/adminCompany" className="sidebar-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsRepeat className="icon" /> Organization
            </li>
          </Link>

          <Link to="" className="sidebar-link" onClick={(e) => { e.preventDefault(); handleLogout(); }}>
            <li className="sidebar-list-item" onClick={handleItemClick}>
              <MdLogout className="icon" /> LogOut
            </li>
          </Link>
        </ul>
      </aside>

      <header className='header'>
        <div className='menu-icon'>
          <BsJustify className='icon' onClick={OpenSidebar} />
        </div>
      </header>
      {showLogoutConfirmation && (
  <div className="modal-backdrop">
    <div className="modal-content">
      <h4 className="modal-title">Confirm Logout</h4>
      <p>Are you sure you want to logout?</p>
      <div className="modal-footer">
        <button onClick={handleCloseLogoutModal} className="btn btn-secondary">
          Cancel
        </button>
        <button onClick={handleConfirmLogout} className="btn btn-primary">
          Logout
        </button>
      </div>
    </div>
  </div>
)}
    </>
  );
}

export default Sidebar;
